import React, { createContext, useState } from "react";

export const UserAccountDataContext = createContext(null);

export const UserAccountDataProvider = ({ children }) => {
    const [userAccountData, setUserAccountData] = useState(null);

    const updateUserAccountData = (newUserData) => {
        setUserAccountData(newUserData);
    };

    return (
        <UserAccountDataContext.Provider value={{ userAccountData, updateUserAccountData }}>
            {children}
        </UserAccountDataContext.Provider>
    );
};