import React, {useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import { useEffect } from "react";
import Switch from '@mui/material/Switch';
import SideBar from '../components/SideBar';
import Header from '../components/Header';

styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#0AD7A5",
        '&:hover': {
            backgroundColor: alpha("#0AD7A5", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#0AD7A5",
    },
}));

const label = { inputProps: { 'aria-label': 'Phone sign in' } };

function DataAndPrivacy() {

    const sidebarRef = useRef()
    const initialDialogState = {
        deleteOpen: false,
    }

    function cancelUserAccountDialog(dialog) {
        setDialogOpen({ ...dialogOpen, [dialog]: false })
    }

    const [dialogOpen, setDialogOpen] = useState(initialDialogState);

    function openSidebar() {
        sidebarRef.current.setSidebarDisplay("block");
    }

    const [deleteProfileOpen, setDeleteProfileOpen] = React.useState(false);

    const handleDeleteProfileClickOpen = () => {
        setDeleteProfileOpen(true);
    };

    const handleDeleteProfileClose = () => {
        setDeleteProfileOpen(false);
    };


    return <div className='flex h-screen bg-background-color'>
        <SideBar ref={sidebarRef}></SideBar>

        {/* <This is the main content */}
        <div className="overflow-y-auto grow">
            <div className="flex-0">
                <div className="px-6 py-5 text-slate-800 flex justify-between">
                    <div className="flex items-center gap-3 transition-all	ease-out delay-100 duration-400 lg:gap-0">
                        <span className="material-symbols-rounded text-accent-color text-xl transition-all ease-out delay-100 duration-400 block lg:invisible cursor-pointer"
                              id="menu-btn" onClick={openSidebar}>
                            menu
                        </span>
                    </div>
                    <Header/>
                </div>
            </div>
            <div className='p-6'>
                <div className='sm:justify-start mt-4 xl:w-3/4'>
                    <h3 className='text-title'>Data & privacy</h3>
                    <h3 className='mt-3 font-light text-paragraph'>Key privacy options to help you choose the data saved in your account, the ads you see, info you share with others, and more</h3>
                </div>
                <div className='mt-8 sm:justify-start xl:w-3/4'>
                    <h3 className='text-title font-normal'>Info you can share with others</h3>
                    <h3 className='mt-3 font-light text-paragraph'>Personal info you’ve saved in your account, like your birthday or email address, and options to manage it</h3>
                </div>

                <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5 px-8 py-6">

                        <a className="flex hover:bg-slate-50 cursor-pointer" target="__blank" href='https://fixale.com/privacypolicy'>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Privacy policy</h3>
                            <h3 className="grow">Privacy policy for your data</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50" target="__blank" href='https://fixale.com/termsofservice'>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Terms of Service</h3>
                            <h3 className="grow">Terms of service of fixale products</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50" target="__blank" href='https://fixale.com/returnpolicy'>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Return policy</h3>
                            <h3 className="grow">Return policy of the products you purchase</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>
                    </div>
                </div>

                <div className='sm:justify-start mt-8 xl:w-3/4'>
                    <h3 className='text-title'>More options</h3>
                    <h3 className='mt-3 font-light text-paragraph'>Other options to manage your data, like choosing what happens to it when your account becomes inactive or deleting your account</h3>
                </div>

                <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5 px-8 py-6">

                        <a className="flex hover:bg-slate-50 cursor-pointer"  onClick={() => { setDialogOpen({ ...dialogOpen, deleteOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Delete your Fixale account</h3>
                            <h3 className="grow">Delete your entire account and data</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                    </div>
                </div>
            </div>
        </div>

        <Dialog open={deleteProfileOpen} onClose={handleDeleteProfileClose} className="round">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Delete Profile</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">Delete your profile from Fixale, the profile and all the info related this account will be deleted. Its not reversable</h3>
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={handleDeleteProfileClose}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white">DELETE</button>
                </div>
            </div>
        </Dialog>

        <dialog className="dialog" id="modal-recovery-phone-number">
            <div className="dialog__wrapper">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your recobery mobile number</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">ecovery mobile number of the user helps you reset the password incase you lost your account</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="number" name="billingaddress" id="billingaddress"
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph dialog__close">CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white">DONE</button>
                </div>
            </div>
        </dialog >

        <Dialog open={dialogOpen.deleteOpen} onClose={() => cancelUserAccountDialog('deleteOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Delete your fixale account</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">To delete your account with all the associated data mail us at support@fixale.com</h3>
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={() => cancelUserAccountDialog('deleteOpen')}>OK</button>
                </div>
            </div>
        </Dialog>
    </div>
}
export default DataAndPrivacy;