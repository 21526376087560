import {getCurrentSession, getUserAccount} from "../utils/AppWrite";
import {useEffect, useState} from "react";
import {Navigate, Outlet, useNavigate} from "react-router-dom";

function NonUserRoutes() {

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getUserAccount().then(userAccountData => {
            setIsLoading(false);
            navigate("/");
        }).catch(e => {
            setIsLoading(false);
        })
    }, [])

    if (isLoading) {
        return (<div></div>)
    }

    return <Outlet />
}

export default NonUserRoutes;