import React, { useContext, useEffect, useState } from 'react';
import { getUserAccount, getUserInitialsImage, getUserProfileImage, logout } from "../utils/AppWrite";
import { useNavigate } from "react-router-dom";
import { UserAccountDataContext } from "../utils/UserAccountDataProvider";
import { Snackbar } from '@mui/material';

function Header() {

    const navigate = useNavigate();

    const { userAccountData, updateUserAccountData } = useContext(UserAccountDataContext);

    const [userProfileImage, setUserProfileImage] = useState("");

    const [userAccount, setUserAccount] = useState({ name: "" });

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    useEffect(() => {
        if (userAccountData) {
            setUserAccount(userAccountData);
            return;
        }

        getUserAccount().then(account => {
            updateUserAccountData(account);
            setUserAccount(account);
        }).catch(error => {
            handleClick({
                vertical: 'bottom',
                horizontal: 'left',
            })
        });
    }, []);

    useEffect(() => {
        getUserProfileImage(userAccount.$id).then(response => {
            setUserProfileImage(response.href);
        }).catch(error => {
            getUserInitialsImage().then((imageUrl) => {
                setUserProfileImage(imageUrl.href)
            })
        })
    }, [userAccount])

    function logoutUser() {
        logout().then(() => {
            navigate("/login");
        }).catch(e => {
            console.log(e)
        })
    }

    return <>
        <div className="flex items-center gap-4">
            <img src={userProfileImage} onError={() => setUserProfileImage(`https://faw.fixale.com/v1/avatars/initials?name=${userAccount.name}&project=63e56e42b7f8dd341816`)}
                alt="Profile pic" className='h-9 rounded-full' />
            <span className="material-symbols-rounded cursor-pointer" onClick={logoutUser}>
                logout
            </span>
        </div>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Error updating user data"
            key={vertical + horizontal}
        />
    </>
}
export default Header;