import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {confirmVerificationEmail, createVerificationEmail, getUserAccount} from "../utils/AppWrite";

function ConfirmEmail() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [title, setTitle] = useState("Confirm Your Email")
    const [message, setMessage] = useState("Your email address is not verified. Please check your email inbox and confirm your email to continue using Fixale Services.")

    function sendConfirmationEmail() {
        createVerificationEmail().then(response => {
            if (response.status === "success") {
                setMessage(`An email has been sent to your inbox. Please check your email inbox and confirm your email to continue using Fixale Services.`)
            }else {
                setMessage(`Failed to Send Confirmation Email! ${response.message}`)
            }
        }).catch(e => {
            setMessage(`Failed to Send Confirmation Email! Unexpected error`)
        })
    }

    useEffect(() => {
        if (searchParams.get("userId") && searchParams.get("secret")) {
            setTitle(`Confirming Your Email...`);
            setMessage("Verifying your email address...");
            confirmVerificationEmail(searchParams.get("userId"), searchParams.get("secret")).then(response => {
                if (response.status === "success") {
                    setMessage(`Your email address has been verified successfully!`);
                    navigate("/");
                }else {
                    setMessage(`Failed to Confirm Email! ${response.message}`)
                }
            }).catch(e => {
                setMessage(`Failed to Confirm Email! Unexpected error`)
            })
        }else {
            getUserAccount().then(userAccountData => {
                if (userAccountData.emailVerification === true) {
                    navigate("/");
                }
            }).catch(e => {
                navigate("/login");
            });
        }
    }, []);
    return (
        <div className="overflow-x-hidden relative bg-background-color">
            <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
                <div className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border-0 sm:bg-white flex flex-col justify-center">
                    <h3 className="text-md font-semibold mb-4 w-full text-center sm:mt-2">{title}</h3>
                    <div className="mb-2">
                        <div className="flex flex-col">

                        </div>
                    </div>
                    <h3 className="text-paragraph decoration-indigo-600 w-full text-center">{message}</h3>
                    <button className="w-full mt-4" onClick={sendConfirmationEmail}>
                        <h3 className="rounded bg-accent-color text-white px-4 py-1.5">
                            Resend Email
                        </h3>
                    </button>

                </div>
                <h3 className="text-paragraph text-slate-700 mt-4">&copy; Copyright 2024
                    <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new Date().getFullYear());</script>, Fixale Softwares PVT LTD.
                </h3>
            </div>
        </div>
    )
}

export default ConfirmEmail;