import React, { useEffect, useState } from 'react'
import {useNavigate, useSearchParams} from "react-router-dom";
import {getUserAccount, getUserJwtToken} from "../utils/AppWrite";
import axios from 'axios';

function Authentication() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [rotationAngle, setRotationAngle] = useState(0); // State to manage rotation angle
    const [authToken, setAuthToken] = useState('');
    const [message, setMessage] = useState('Authenticating.. Please wait');

    useEffect(() => {
        if (!window.opener) {
            setMessage(`Authentication not allowed from this webpage. Please report this incident to the Support Team.`)
            return;
        }

        const token = searchParams.get('authToken');

        if (token) {
            setAuthToken(token);
        }else {
            setMessage('Authentication failed! AuthToken not provided.');
            setTimeout(() => {
                window.close();
            }, 1000);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setRotationAngle(prevAngle => prevAngle + 1); // Increment rotation angle
        }, 10);

        return () => {
            clearInterval(interval); // Clear interval on component unmount
        };
    }, []);

    useEffect(() => {
        if (!authToken) {
            return;
        }

        validateOAuthToken(authToken, (authTokenResponse) => {
            if (authTokenResponse.status === "success") {
                getUserAccount().then(userAccountData => {
                    if (userAccountData.emailVerification === false) {
                        setTimeout(() => {
                            sendResponseToParent(authTokenResponse.OAuthTokenData.platformUrl, {
                                status: 'failed',
                                message: 'Email not verified, Kindly verify your email address fist'
                            });
                        }, 1000);
                        return;
                    }

                    getUserJwtToken().then(userJwtToken => {
                        let data = JSON.stringify({
                            "oAuthToken": authToken,
                            "userJwtToken": userJwtToken
                        });

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: 'https://fawauthbackend.fixale.com/v1/OAuth/authenticateUser',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            data : data
                        };

                        axios.request(config)
                            .then((response) => {
                                let responseData = response.data;

                                if (responseData.status === "success") {
                                    setMessage('Successfully Authenticated User, This window will close automatically');
                                    setTimeout(() => {
                                        sendResponseToParent(authTokenResponse.OAuthTokenData.platformUrl, responseData);
                                        window.close();
                                    }, 1000);
                                }else {
                                    setMessage('Authentication failed! ' + responseData.message);
                                }
                            })
                            .catch((error) => {
                                setMessage('Failed to Authenticate User');
                                setTimeout(() => {
                                    sendResponseToParent(authTokenResponse.OAuthTokenData.platformUrl, {
                                        status: 'failed',
                                        message: 'Failed to Authenticate User'
                                    });
                                }, 1000);
                            });
                    }).catch(e => {
                        setMessage('Failed to Get User Authentication');
                        setTimeout(() => {
                            sendResponseToParent(authTokenResponse.OAuthTokenData.platformUrl,{
                                status: 'failed',
                                message: 'Failed to Get User Authentication'
                            });
                        }, 1000);
                    });
                }).catch(e => {
                    navigate("/login?next=/auth?authToken=" + authToken);
                })
            }else {
                setMessage('Authentication failed! Invalid AuthToken.');
                setTimeout(() => {
                    window.close();
                }, 1000);
            }
        });
    }, [authToken])

    function sendResponseToParent(platformUrl, responseData) {
        window.opener.postMessage(JSON.stringify(responseData), platformUrl);
    }
    
    function validateOAuthToken(oAuthToken, callback = () => {}) {
        let data = JSON.stringify({
            "oAuthToken": oAuthToken
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://fawauthbackend.fixale.com/v1/OAuth/validateAuthToken',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios.request(config)
            .then((response) => {
                callback(response.data)
            })
            .catch((error) => {
                callback({
                    status: "failed",
                    message: "Unexpected error"
                })
            });
    }

    const imageStyle = {

        transform: `rotate(${rotationAngle}deg)` // Apply rotation
    };

    return (
        <div className='grid place-content-center h-screen'>
            <div className='items-center flex flex-col items-center'>
                <img className="w-10" src="loading.png" alt="" srcSet="" style={imageStyle} />
                <h1 className='mt-2'>{message}</h1>
            </div>
        </div>
    );
}

export default Authentication;