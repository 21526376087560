import React, { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import {createVerificationEmail, loginUser, signUpUser} from "../utils/AppWrite";
import DynamicSnackbar from '../components/DynamicSnackbar';

function SignUp() {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('error'); // or 'error', 'warning', 'info'

    const showSnackbar = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function signUp() {
        if (!email || !password || !confirmPassword) {
            showSnackbar("Please fill in the required fields", "error");
            return;
        }

        signUpUser(email.trim(), password.trim(), confirmPassword.trim()).then(response => {
            if (response.status === "success") {
                showSnackbar("Signed up successfully", "success");
                createVerificationEmail();
                loginUser(email.trim(), password.trim()).then(response => {
                    navigate("/");
                }).catch(e => {
                    showSnackbar("Failed to login User automatically, Please try again.", "error");
                })
            } else {
                showSnackbar(response.message, "error");
            }
        }).catch(e => {
            showSnackbar("Unexpected error", "error");
        })
    }

    return (
        <>
            <div className="overflow-x-hidden relative bg-background-color">
                <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
                    <div className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border-0 sm:bg-white flex flex-col justify-center">
                        <h3 className="text-md font-semibold mb-1 w-full text-center sm:mt-2">Create Your Own Fixale Account</h3>
                        <h3 className="text-paragraph decoration-indigo-600 w-full text-center mb-8">Create a new Fixale account to access products such as Niotron Builder, IDE, etc</h3>
                        <div className='flex flex-col gap-2'>
                            <input type="email" name="email" id="email" placeholder='Email'
                                className='border rounded px-2 py-1.5 text-paragraph' value={email} onChange={e => setEmail(e.target.value)} />
                            <input type="password" name="password" id="password" placeholder='Password'
                                className='border rounded px-2 py-1.5 text-paragraph' value={password} onChange={e => setPassword(e.target.value)} />
                            <input type="password" name="cnfpassword" id="cnfpassword" placeholder='Confirm Password'
                                className='border rounded px-2 py-1.5 text-paragraph' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        </div>
                        <h1 className='text-paragraph decoration-indigo-600 w-full text-center mb-2'>By clicking Create
                            Account I accept that i've read <a href="https://fixale.com/privacypolicy" target="_blank" rel="noopener noreferrer" className='text-accent-color'>the privacy policy</a> and
                            accepted the <a href="https://fixale.com/termsofservice" target="_blank" rel="noopener noreferrer"
                                className='text-accent-color'>terms of service</a></h1>
                        <h1 className='text-paragraph decoration-indigo-600 w-full text-center mb-2'><Link to="/reset" className='text-accent-color font-bold underline underline-offset-1 decoration-blue-300 decoration-2'>Forgot password</Link></h1>
                        <button className="w-full mt-4" onClick={signUp}>
                            <h3 className="rounded bg-accent-color text-white px-4 py-1.5">Create Account</h3>
                        </button>
                        <h1 className='text-paragraph decoration-indigo-600 w-full text-center mb-4 mt-2'>Already have an account? <Link to="/login" className='text-accent-color'>Login</Link> instead</h1>

                    </div>
                    <h3 className="text-paragraph text-slate-700 mt-4">&copy; Copyright 2024
                        <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new Date().getFullYear());</script>, Fixale Softwares PVT LTD.
                    </h3>
                </div>
            </div>
            <DynamicSnackbar open={open} message={message} onClose={handleClose} severity={severity} />
        </>
    )
}

export default SignUp;