import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import SideBar from '../components/SideBar';
import Header from '../components/Header';
import { getUserSessions } from "../utils/AppWrite";
import { Snackbar } from '@mui/material';

const FixaleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: "#0AD7A5",
        '&:hover': {
            backgroundColor: alpha("#0AD7A5", theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: "#0AD7A5",
    },
}));

const label = {};

function Security() {

    const sidebarRef = useRef()

    const [userSessions, setUserSessions] = useState([])

    const [state, setState] = React.useState({
        open: false,
        vertical: 'bottom',
        horizontal: 'left',
    });
    const { vertical, horizontal, open } = state;

    const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    useEffect(() => {
        getUserSessions().then(sessions => {
            setUserSessions(sessions.sessions);
        }).catch(e => {
            handleClick({
                vertical: 'bottom',
                horizontal: 'left',
            })
        })
    }, [])

    function openSidebar() {
        sidebarRef.current.setSidebarDisplay("block");
    }

    const initialDialogState = {
        passwordOpen: false,
    }

    function cancelUserAccountDialog(dialog) {
        setDialogOpen({ ...dialogOpen, [dialog]: false })
    }

    const [dialogOpen, setDialogOpen] = useState(initialDialogState);

    return <>
        <div className='flex h-screen bg-background-color'>
            <SideBar ref={sidebarRef}></SideBar>

            {/* <This is the main content */}
            <div className="overflow-y-auto grow">
                <div className="flex-0">
                    <div className="px-6 py-5 text-slate-800 flex justify-between">
                        <div className="flex items-center gap-3 transition-all	ease-out delay-100 duration-400 lg:gap-0">
                            <span className="material-symbols-rounded text-accent-color text-xl transition-all ease-out delay-100 duration-400 block lg:invisible cursor-pointer"
                                id="menu-btn" onClick={openSidebar}>
                                menu
                            </span>
                        </div>
                        <Header />
                    </div>
                </div>
                <div className='p-6'>
                    <div className='sm:justify-start mt-4 xl:w-3/4'>
                        <h3 className='text-title'>Data & privacy</h3>
                        <h3 className='mt-3 font-light text-paragraph'>Key privacy options to help you choose the data saved in your account, the ads you see, info you share with others, and more</h3>
                    </div>
                    <div className='mt-8 sm:justify-start xl:w-3/4'>
                        <h3 className='text-title font-normal'>Signing in to Fixale</h3>
                    </div>

                    <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                        <div className="flex flex-col gap-2 px-8 py-6">

                            <a className="flex hover:bg-slate-50 cursor-pointer items-center">
                                <h3 className="flex-none text-slate-600 font-semibold w-2/5">New Session Reminders</h3>
                                <h3 className="grow">Coming Soon...</h3>
                                <FixaleSwitch {...{ inputProps: { 'aria-label': 'Phone sign in' } }} disabled />
                            </a>

                            <a className="pt-2 flex hover:bg-slate-50 cursor-pointer items-center" target="__blank" href='https://auth.fixale.com'>
                                <h3 className="flex-none text-slate-600 font-semibold w-2/5">2-step verification</h3>
                                <h3 className="grow">Enable it On Fixale Auth Dashboard</h3>
                                <span className="material-symbols-rounded text-accent-color">
                                    navigate_next
                                </span>
                            </a>
                        </div>
                    </div>

                    <div className='sm:justify-start mt-8 xl:w-3/4'>
                        <h3 className='text-title'>Your devices</h3>
                        <h3 className='mt-3 font-light text-paragraph'>Devices you logged in using Fixale Account. Detailed Session Details can be seen in Fixale Auth Dashboard</h3>
                    </div>

                    <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                        <div className="flex flex-col gap-5 px-8 py-6">
                            {userSessions.map(userSession => (
                                <a className="flex hover:bg-slate-50 cursor-pointer">
                                    <h3 className="flex-none text-slate-600 font-semibold w-2/5">{userSession.osName}</h3>
                                    <h3 className="grow">{userSession.deviceModel}</h3>
                                    <h3 className="grow">{userSession.countryName}</h3>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={dialogOpen.passwordOpen} onClose={() => cancelUserAccountDialog('passwordOpen')} className="rounded">
                <div className="p-4">
                    <div className="px-4 mb-4">
                        <h3 className="font-medium text-lg">Enter your new password</h3>
                        <h3 className="text-paragraph mt-1 tracking-normal">Contact email of the user helps us recognize
                            your account, and send important notifications</h3>
                    </div>
                    <div className="px-4 mb-4 text-paragraph">
                        <input type="password" name="password" id="password" placeholder='Enter old password'
                            className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                    </div>
                    <div className="px-4 mb-4 text-paragraph">
                        <input type="password" name="new-password" id="new-password" placeholder='Enter new password'
                            className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                    </div>
                    <div className="px-4 mb-4 text-paragraph">
                        <input type="password" name="confirm-password" id="confirm-password" placeholder='Confirm password'
                            className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                    </div>
                    <div className="flex flex-row justify-end px-4 gap-4">
                        <button
                            className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserAccountDialog('passwordOpen')}>CANCEL</button>
                        <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white">DONE</button>
                    </div>
                </div>
            </Dialog>
        </div>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={handleClose}
            message="Error getting user session"
            key={vertical + horizontal}
        />
    </>
}
export default Security;