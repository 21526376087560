import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useState} from 'react';
import {
    Link
} from "react-router-dom";

function SideBar(props, ref) {

    const [sidebarDisplay, setSidebarDisplay] = useState("block");

    useLayoutEffect(() => {
        function updateSidebarDisplay() {
            if (window.innerWidth <= 1024) {
                setSidebarDisplay("none")
            }else {
                setSidebarDisplay("block")
            }
        }
        window.addEventListener('resize', updateSidebarDisplay);
        updateSidebarDisplay();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            setSidebarDisplay: setSidebarDisplay
        }
    })

    return <aside className='overflow-y-auto h-screen flex-none w-3/5 sm:w-2/4 md:w-2/5 xl:w-1/5 bg-white sm:bg-background-color z-10 lg:z-0 sidebar -left-full lg:left-0 fixed lg:static hidden lg:block'
    style={{display: sidebarDisplay}}>
        <div className='py-6 pl-2 sm:pl-10'>
            <div className="flex items-center gap-2 justify-between pr-6">
                <img src="fixale.png" alt="Fixale Logo" className='h-6' />
                <span className="material-symbols-rounded lg:invisible text-accent-color cursor-pointer" id="close-btn" onClick={() => {setSidebarDisplay("none")}}>
                    close
                </span>
            </div>
            <div className='flex flex-col gap-6 mt-12'>
                <Link to={`/`} className='flex items-center gap-4'>
                    <span className="material-symbols-rounded">
                        home
                    </span>
                    <h3>Home</h3>
                </Link>
                <Link to={`/personalinfo`} className='flex items-center gap-4'>
                    <span className="material-symbols-rounded">
                        badge
                    </span>
                    <h3>Personal Info</h3>
                </Link>
                <Link to={`/dataprivacy`} className='flex items-center gap-4'>
                    <span className="material-symbols-rounded">
                        post_add
                    </span>
                    <h3>Data and Privacy</h3>
                </Link>
                <Link to={`/security`} className='flex items-center gap-4'>
                    <span className="material-symbols-rounded">
                        lock
                    </span>
                    <h3>Security</h3>
                </Link>
                <Link to={`/payments`} className='flex items-center gap-4'>
                    <span className="material-symbols-rounded">
                        account_balance
                    </span>
                    <h3>Payments and Subscriptions</h3>
                </Link>
            </div>
        </div>
    </aside>
}
export default forwardRef(SideBar);