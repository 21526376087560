import {Account, Storage, Client, ID, Databases, Avatars} from "appwrite"

const client = new Client()
    .setEndpoint(process.env.REACT_APP_FAW_ENDPOINT)
    .setProject(process.env.REACT_APP_FAW_PROJECT_ID);

const account = new Account(client);
const avatars = new Avatars(client);
const storage = new Storage(client);
const databases = new Databases(client);

export const loginUser = async (email, password) => {
    try {
        await account.createEmailSession(email, password);
        return {
            status: "success",
            message: "User logged in Successfully"
        }
    }catch (e) {
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const loginUserWithGoogle = async () => {
    try {
        await account.createOAuth2Session('google', process.env.REACT_APP_FAW_SUCCESS_REDIRECT_URL, process.env.REACT_APP_FAW_FAILURE_REDIRECT_URL);
        return {
            status: "success",
            message: "User logged in Successfully"
        }
    }catch (e) {
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const signUpUser = async (email, password, confirmPassword) => {
    try {
        if (password !== confirmPassword) {
            return {
                status: "failed",
                message: "Passwords does not match"
            }
        }
        await account.create(ID.unique(), email, password);
        return {
            status: "success",
            message: "User Signed up Successfully"
        }
    }catch (e) {
        if (e.message === "A user with the same email already exists in the current project.") {
            e.message = "A user with the same email already exists"
        }
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const createVerificationEmail = async () => {
    try {
        await account.createVerification(process.env.REACT_APP_FAW_SUCCESS_REDIRECT_URL + "/confirm");
        return {
            status: "success",
            message: "Sent Confirmation Email"
        }
    }catch (e) {
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const confirmVerificationEmail = async (userId, secret) => {
    try {
        await account.updateVerification(userId, secret);
        return {
            status: "success",
            message: "User Email Verified Successfully"
        }
    }catch (e) {
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const createPasswordResetEmail = async (email) => {
    try {
        await account.createRecovery(email, process.env.REACT_APP_FAW_SUCCESS_REDIRECT_URL + "/reset");
        return {
            status: "success",
            message: "Sent Password Reset Email"
        }
    }catch (e) {
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const confirmUserPasswordReset = async (userId, secret, password, confirmPassword) => {
    try {
        await account.updateRecovery(userId, secret, password, confirmPassword);
        return {
            status: "success",
            message: "User Password Reset Successfully"
        }
    }catch (e) {
        return {
            status: "failed",
            message: e.message
        }
    }
}

export const getCurrentSession = async () => {
    try {
        return account.getSession('current');
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserSessions = async () => {
    try {
        return await account.listSessions();
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserJwtToken = async () => {
    try {
        let jwtResponse = await account.createJWT();
        return jwtResponse.jwt;
    }catch (appwriteError) {
        throw new Error(appwriteError);
    }
}

export const logout = async () => {
    try {
        return await account.deleteSession('current')
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserAccount = async () => {
    try {
        return await account.get();
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserData = async (userId) => {
    try {
        return await databases.getDocument(process.env.REACT_APP_FAW_USER_DATABASE_ID, process.env.REACT_APP_FAW_USER_COLLECTION_ID, userId);
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const updateUserName = async (name) => {
    try {
        return await account.updateName(name);
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const updateUserEmail = async (email, password) => {
    try {
        return await account.updateEmail(email, password);
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const updateUserPhone = async (phone, password) => {
    try {
        return await account.updatePhone(phone, password);
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const updateUserPassword = async (password, oldPassword) => {
    try {
        //Old password may not be there for users who authenticated with OAuth
        if (oldPassword) {
            return await account.updatePassword(password, oldPassword);
        }else {
            return await account.updatePassword(password);
        }
    } catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

//Database Functions
export const updateDocument = async (documentID, data) => {
    try {
        return await databases.updateDocument(process.env.REACT_APP_FAW_USER_DATABASE_ID, process.env.REACT_APP_FAW_USER_COLLECTION_ID, documentID, data);
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

//Storage Functions

export const getUserProfileImage = async (userId) => {
    try {
        return await storage.getFilePreview(process.env.REACT_APP_FAW_USER_BUCKET, userId)
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const getUserInitialsImage = async () => {
    try {
        return await avatars.getInitials();
    }catch (appwriteError) {
        throw new Error(appwriteError)
    }
}

export const updateUserProfileImage = async (userId) => {
    try {
        return await storage.updateFile()
    }catch (appwriteError){
        throw new Error(appwriteError)
    }
}