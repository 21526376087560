import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const DynamicSnackbar = ({ open, message, onClose, severity = 'success', action }) => {
  return (
    <Snackbar open={open} autoHideDuration={2000} onClose={onClose} action={action}>
      <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default DynamicSnackbar;
