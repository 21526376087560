import React, {useState, useEffect} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {confirmUserPasswordReset, createPasswordResetEmail} from "../utils/AppWrite";

// Component for rendering when there is no parameter
const DefaultComponent = () => {
    const [email, setEmail] = useState("");

    const [message, setMessage] = useState("Rest your password using a link sent to your email. Enter your email to reset your password");
    const [buttonText, setButtonText] = useState("Send Password Reset Email");

    function sendPasswordRestEmail() {
        if (!email) {
            return;
        }

        createPasswordResetEmail(email).then(response => {
            if (response.status === "success") {
                setButtonText("Password Reset Email Sent");
                setMessage("A password reset email has been sent to your email inbox. Please check your email inbox and reset your password.");
            } else {
                setMessage(`Failed to Send Password Reset Email! ${response.message}`);
            }
        }).catch(e => {
            setMessage(`Failed to Send Password Reset Email! Unexpected error`);
        })
    }

    return <div className="overflow-x-hidden relative bg-background-color">
        <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
            <div
                className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border-0 sm:bg-white flex flex-col justify-center">
                <h3 className="text-md font-semibold mb-4 w-full text-center sm:mt-2">Reset Password</h3>
                <div className="mb-2">
                    <div className="flex flex-col">

                    </div>
                </div>
                <h3 className="text-paragraph decoration-indigo-600 w-full text-center">{message}</h3>
                <input type="text" name="username" id="username" placeholder='Email'
                       className='border rounded px-2 py-1.5 text-paragraph mt-2' value={email} onChange={e => {
                    setEmail(e.target.value)
                }}/>
                <button className="w-full mt-4" onClick={sendPasswordRestEmail}>
                    <h3 className="rounded bg-accent-color text-white px-4 py-1.5">
                        {buttonText}
                    </h3>
                </button>

            </div>
            <h3 className="text-paragraph text-slate-700 mt-4">&copy; Copyright 2024
                <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new
                    Date().getFullYear());</script>, Fixale Softwares PVT LTD.
            </h3>
        </div>
    </div>;
};

// Component for rendering when there is a parameter
const ParameterComponent = () => {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    function resetUserPassword() {
        if (!password || !confirmPassword) {
            //TODO: Show Snackbar message asking users to enter both the fields
            return;
        }

        if (password !== confirmPassword) {
            //TODO: Show Snackbar message that the passwords does not match
            return;
        }

        confirmUserPasswordReset(searchParams.get("userId"), searchParams.get("secret"), password.trim(), confirmPassword.trim()).then(response => {
            if (response.status === "success") {
                navigate("/login");
            } else {
                //TODO: Show error Snackbar
            }
        }).catch(e => {
            //TODO: Show error Snackbar
        })
    }

    return <div className="overflow-x-hidden relative bg-background-color">
        <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
            <div
                className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border-0 sm:bg-white flex flex-col justify-center">
                <h3 className="text-md font-semibold mb-4 w-full text-center sm:mt-2">Reset Password</h3>
                <div className="mb-2">
                    <div className="flex flex-col">

                    </div>
                </div>
                <h3 className="text-paragraph decoration-indigo-600 w-full text-center">Enter the new password you want
                    to keep and confirm your password change</h3>
                <input type="password" name="password" id="password" placeholder='Password' value={password} onChange={e => { setPassword(e.target.value) }}
                       className='border rounded px-2 py-1.5 text-paragraph mt-2'/>
                <input type="password" name="cnfpassword" id="cnfpassword" placeholder='Confirm Password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                       className='border rounded px-2 py-1.5 text-paragraph mt-2'/>
                <button className="w-full mt-4" onClick={resetUserPassword}>
                    <h3 className="rounded bg-accent-color text-white px-4 py-1.5">
                        Change Password
                    </h3>
                </button>

            </div>
            <h3 className="text-paragraph text-slate-700 mt-4">&copy; Copyright 2024
                <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new
                    Date().getFullYear());</script>, Fixale Softwares PVT LTD.
            </h3>
        </div>
    </div>;
};

function ResetPassword() {

    const [searchParams] = useSearchParams();

    return (searchParams.get("userId") && searchParams.get("secret")) ? <ParameterComponent/> : <DefaultComponent/>;
}

export default ResetPassword;