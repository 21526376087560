import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {loginUser, loginUserWithGoogle} from "../utils/AppWrite";
import DynamicSnackbar from '../components/DynamicSnackbar';

function Login() {

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [nextUrl, setNextUrl] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('error'); // or 'error', 'warning', 'info'

  const showSnackbar = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (searchParams.get("next")) {
      if (searchParams.get("next").startsWith("/")) {
        setNextUrl(searchParams.get("next"));
      }
    }
  }, []);

  function login() {
    if (!email || !password) {
      showSnackbar("Please fill in the required fields", "error");
      return;
    }

    loginUser(email.trim(), password.trim()).then(response => {
      if (response.status === "success") {
        showSnackbar("Logged in successfully", "success");
        if (nextUrl) {
            navigate(nextUrl);
            return;
        }
        navigate("/");
      } else {
        showSnackbar(response.message, "error");
      }
    }).catch(e => {
      showSnackbar("Unexpected error", "error");
    })
  }
  
  function loginWithGoogle() {
    loginUserWithGoogle().then(response => {
      if (response.status === "success") {
        showSnackbar("Logged in successfully", "success");
        if (nextUrl) {
          navigate(nextUrl);
          return;
        }
        navigate("/");
      } else {
        showSnackbar(response.message, "error");
      }
    }).catch(e => {
      showSnackbar("Unexpected error", "error");
    })
  }

  return (
    <>
      <div className="overflow-x-hidden relative bg-background-color">
        <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
          <div className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border-0 sm:bg-white flex flex-col justify-center">
            <h3 className="text-md font-semibold mb-1 w-full text-center sm:mt-2">Login To Your Fixale Account</h3>
            <h3 className="text-paragraph decoration-indigo-600 w-full text-center mb-8">Login to access any Fixale Products, Your Fixale account can be used to login into Nitron Builder, IDE, etc</h3>
            <div className='flex flex-col gap-2'>
              <input type="text" name="username" id="username" placeholder='Email' className='border rounded px-2 py-1.5 text-paragraph' value={email} onChange={e => setEmail(e.target.value)} />
              <input type="password" name="password" id="password" placeholder='Password' className='border rounded px-2 py-1.5 text-paragraph' value={password} onChange={e => { setPassword(e.target.value) }} />
            </div>
            <div className='text-paragraph bg-orange-100 border border-orange-200 rounded mb-2'>
              <h1 className='text-center py-1.5 px-1'>We've migrated from the old authentication system. If you are using email and password to authenticate, Kindly Reset your Password once. Google Authenticated users can still login as usual.</h1>
            </div>
            <h1 className='text-paragraph decoration-indigo-600 w-full text-center mb-2'><Link to="/reset" className='text-accent-color font-bold underline underline-offset-1 decoration-blue-300 decoration-2'>Forgot password</Link></h1>
            <button className="w-full mt-2" onClick={login}>
              <h3 className="rounded bg-accent-color text-white px-4 py-1.5">Sign In</h3>
            </button>
            <h3 className='text-paragraph text-color-grey-dark decoration-indigo-600 w-full text-center my-2'>OR</h3>
            <button onClick={loginWithGoogle}>
              <h3 className="rounded border border-accent-color text-accent-color px-4 py-1.5">
                Continue with Google
              </h3>
            </button>
            <h1 className='text-paragraph decoration-indigo-600 w-full text-center mb-4 mt-2'>Don't have an account? <Link to="/signup" className='text-accent-color'>Create a New Account</Link> instead</h1>

          </div>
          <h3 className="text-paragraph text-slate-700 mt-4">&copy; Copyright 2024
            <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new Date().getFullYear());</script>, Fixale Softwares PVT LTD.
          </h3>
        </div>
      </div>
      <DynamicSnackbar open={open} message={message} onClose={handleClose} severity={severity} />
    </>
  )
}

export default Login;