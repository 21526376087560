import React, { useContext, useEffect, useRef, useState } from 'react';
import SideBar from '../components/SideBar';
import { getUserAccount } from "../utils/AppWrite";
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import { UserAccountDataContext } from "../utils/UserAccountDataProvider";
import DynamicSnackbar from '../components/DynamicSnackbar';

function Home() {
    const navigate = useNavigate();
    const sidebarRef = useRef();

    const { userAccountData, updateUserAccountData } = useContext(UserAccountDataContext)

    const [userAccount, setUserAccount] = useState({ name: "" });

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('error'); // or 'error', 'warning', 'info'

    const handleOpen = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function openSidebar() {
        sidebarRef.current.setSidebarDisplay("block");
    }

    //Updating User Data if it is empty
    useEffect(() => {
        if (userAccountData) {
            setUserAccount(userAccountData);
            return;
        }

        getUserAccount().then(account => {
            updateUserAccountData(account);
            setUserAccount(account);
        }).catch(error => {
            handleOpen(error, "error")
        });
    }, [])

    return <>
        <div className='flex h-screen bg-background-color'>
            <SideBar ref={sidebarRef}></SideBar>

            {/* <This is the main content */}
            <div className="overflow-y-auto grow">
                <div className="flex-0">
                    <div className="px-6 py-5 text-slate-800 flex justify-between">
                        <div className="flex items-center gap-3 transition-all	ease-out delay-100 duration-400 lg:gap-0">
                            <span className="material-symbols-rounded text-accent-color text-xl transition-all ease-out delay-100 duration-400 block lg:invisible cursor-pointer"
                                id="menu-btn" onClick={openSidebar}>
                                menu
                            </span>
                        </div>
                        <Header />
                    </div>
                </div>
                <div className='p-6'>
                    <div className='sm:justify-start mt-4 xl:w-3/4'>
                        <h3 className='text-paragraph'>Welcome 👋</h3>
                        <h3 className='mt-3 font-light text-[25px]'>{userAccount.name}</h3>
                    </div>
                    <div className='sm:justify-start mt-4 xl:w-3/4'>
                        <h3 className='text-title'>Privacy suggestions available</h3>
                        <ul className="list-disc mt-3 font-light text-paragraph list-inside text-gray-800">
                            <li className="mt-2">Use strong and unique passwords: Avoid using common or easily guessable passwords. Create strong passwords with a combination of uppercase and lowercase letters, numbers, and special characters. Consider using a password manager to securely store your passwords.</li>
                            <li className="mt-2">Be cautious with sharing personal information online: Be mindful of the information you share on social media platforms and other websites. Avoid posting sensitive personal details like your full address, phone number, or financial information unless necessary.</li>
                            <li className="mt-2">Be cautious with public Wi-Fi: Avoid accessing sensitive information, such as online banking or shopping, when connected to public Wi-Fi networks. If you must use public Wi-Fi, consider using a virtual private network (VPN) to encrypt your internet traffic and add an extra layer of protection.</li>
                            <li className="mt-2">Use a privacy-focused browser and search engine: Consider using privacy-focused browsers like Firefox or privacy-enhanced search engines like DuckDuckGo. These platforms prioritize user privacy and minimize data tracking.</li>
                        </ul>
                    </div>
                    <div className='sm:justify-start mt-4 xl:w-3/4'>
                        <h3 className='text-title'>Privacy and personalization</h3>
                        <h3 className='mt-3 font-light text-paragraph'>See the data in your Fixale account and choose what activity is saved to personalize your Fixale experience</h3>
                    </div>
                    <div className='mt-8 sm:justify-start xl:w-3/4'>
                        <h3 className='text-title font-normal'>Signing in to Fixale</h3>
                    </div>

                    <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                        <div className="flex flex-col gap-5 px-8 py-6">

                            <a className="flex justify-between hover:bg-slate-50 cursor-pointer" href="https://builder.niotron.com">
                                <h3 className="flex-none text-slate-600 font-semibold w-2/5">Application Builder</h3>
                                <span className="material-symbols-rounded text-accent-color">
                                    navigate_next
                                </span>
                            </a>

                            <a className="flex justify-between hover:bg-slate-50 cursor-pointer" href="http://community.niotron.com">
                                <h3 className="flex-none text-slate-600 font-semibold w-2/5">Community</h3>
                                <span className="material-symbols-rounded text-accent-color">
                                    navigate_next
                                </span>
                            </a>

                            <a className="flex justify-between hover:bg-slate-50 cursor-pointer" href="https://ide.niotron.com">
                                <h3 className="flex-none text-slate-600 font-semibold w-2/5">Extension IDE</h3>
                                <span className="material-symbols-rounded text-accent-color">
                                    navigate_next
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DynamicSnackbar open={open} message={message} onClose={handleClose} severity={severity} />
    </>
}
export default Home;