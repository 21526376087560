import React, {useContext, useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SideBar from '../components/SideBar';
import {
    getUserAccount,
    getUserData,
    getUserProfileImage,
    updateDocument, updateUserEmail,
    updateUserName,
    updateUserPassword, updateUserPhone
} from "../utils/AppWrite"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Snackbar from "@mui/material/Snackbar";
import {UserAccountDataContext} from "../utils/UserAccountDataProvider";

function PersonalInfo() {

    const navigate = useNavigate()
    const sidebarRef = useRef()

    const [snackbarOpened, setSnackbarOpened] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("false");

    const { userAccountData, updateUserAccountData } = useContext(UserAccountDataContext)

    const [userProfileImage, setUserProfileImage] = useState("");

    const [userAccount, setUserAccount] = useState({ name: "" });

    const [toBeUpdatedAccountData, setToBeUpdatedAccountData] = useState({})

    //We Create an Initial State so the page can load without waiting for the Data to be fetched

    const initialDialogState = {
        profileOpen: false,
        nameOpen: false,
        dobOpen: false,
        genderOpen: false,
        emailOpen: false,
        phoneOpen: false,
        addressOpen: false,
        billingAddressOpen: false,
        languageOpen: false,
        passwordOpen: false,
    }

    const initialUserData = {
        DateOfBirth: new Date().toISOString().split('T')[0],
        Gender: "none",
        Address: "",
        Address2: "",
        BillingAddress: "",
        BillingAddress2: "",
        Country: "",
        BillingCountry: "",
        Region: "",
        BillingRegion: ""
    }

    const [dialogOpen, setDialogOpen] = useState(initialDialogState);
    const [userData, setUserData] = useState(initialUserData);
    const [toBeUpdatedUserData, setToBeUpdatedUserData] = useState(initialUserData);

    function showSnackBar(message) {
        setSnackbarMessage(message);
        setSnackbarOpened(true);
    }

    //Getting the User Account Information with the Account API
    useEffect(() => {
        if (userAccountData) {
            setUserAccount(userAccountData);
            return;
        }

        getUserAccount().then(account => {
            updateUserAccountData(account);
            setUserAccount(account);
        }).catch(error => {
            //navigate('/error');
        });
    }, []);

    //Getting the user Data from the database after getting the user account data so that we can use the user account id
    useEffect(() => {
        if (!userAccount.$id) {
            return;
        }

        getUserData(userAccount.$id).then(userDataResponse => {
            setUserData({
                DateOfBirth: userDataResponse.DateOfBirth,
                Gender: userDataResponse.Gender,
                Address: userDataResponse.Address,
                Address2: userDataResponse.Address2,
                BillingAddress: userDataResponse.BillingAddress,
                BillingAddress2: userDataResponse.BillingAddress2,
                Country: userDataResponse.Country,
                BillingCountry: userDataResponse.BillingCountry,
                Region: userDataResponse.Region,
                BillingRegion: userDataResponse.BillingRegion
            })
        }).catch(error => {
            showSnackBar("Failed to Get User Data")
        })

        getUserProfileImage(userAccount.$id).then(response => {
            setUserProfileImage(response.href);
        }).catch(error => {
            //TODO: Show generic profile image
        })
    }, [userAccount])

    //Syncing the toBeUpdatedUserData with the userData
    useEffect(() => {
        setToBeUpdatedUserData(userData)
    }, [userData])


    function openSidebar() {
        sidebarRef.current.setSidebarDisplay("block");
    }

    function cancelUserAccountDialog(dialog) {
        setDialogOpen({ ...dialogOpen, [dialog]: false })
        setToBeUpdatedAccountData({})
    }

    function cancelUserDataDialog(dialog) {
        setDialogOpen({ ...dialogOpen, [dialog]: false })
        setToBeUpdatedUserData(userData)
    }

    function updateUserData() {
        updateDocument(userAccount.$id, toBeUpdatedUserData).then(userDataResponse => {
            setUserData({
                DateOfBirth: userDataResponse.DateOfBirth,
                Gender: userDataResponse.Gender,
                Address: userDataResponse.Address,
                Address2: userDataResponse.Address2,
                BillingAddress: userDataResponse.BillingAddress,
                BillingAddress2: userDataResponse.BillingAddress2,
                Country: userDataResponse.Country,
                BillingCountry: userDataResponse.BillingCountry,
                Region: userDataResponse.Region,
                BillingRegion: userDataResponse.BillingRegion
            })
            setDialogOpen(initialDialogState)
        }).catch(error => {
            showSnackBar("Failed to Update User Data")
        })
    }

    function updateUserAccount() {
        //Update User name
        if (toBeUpdatedAccountData.name) {
            updateUserName(toBeUpdatedAccountData.name).then(response => {
                showSnackBar("User Name has be Updated")
                setUserAccount(response)
            }).catch(error => {
                showSnackBar("Failed to Update User Name")
            })
            setToBeUpdatedAccountData({})
        }

        //Update User Password
        if (toBeUpdatedAccountData.oldPassword || toBeUpdatedAccountData.newPassword || toBeUpdatedAccountData.confirmPassword) {
            if (!(/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/).test(toBeUpdatedAccountData.newPassword)) {
                showSnackBar("Password Should Contain: Min of 8 Characters, One more More Upper and lowercase, a numeric and special character")
                return;
            }

            if (toBeUpdatedAccountData.newPassword !== toBeUpdatedAccountData.confirmPassword) {
                showSnackBar("Passwords does not match")
                return;
            }

            updateUserPassword(toBeUpdatedAccountData.newPassword, toBeUpdatedAccountData.oldPassword).then(response => {
                setUserAccount(response)
                showSnackBar("User Password has been Updated")
            }).catch(error => {
                showSnackBar("Failed to Update User Password")
            })
            setToBeUpdatedAccountData({})
        }

        //Update User email
        if (toBeUpdatedAccountData.email) {
            if (!toBeUpdatedAccountData.emailUpdatePassword) {
                showSnackBar("Invalid Password")
                return;
            }

            updateUserEmail(toBeUpdatedAccountData.email, toBeUpdatedAccountData.emailUpdatePassword).then((response) => {
                setUserAccount(response)
                showSnackBar("User Email has been updated. Please verify your email address again.")
            }).catch(error => {
                showSnackBar("Failed to Update User Email")
            })
            setToBeUpdatedAccountData({})
        }

        //Update User Phone
        if (toBeUpdatedAccountData.phone) {
            if (!toBeUpdatedAccountData.phoneUpdatePassword) {
                showSnackBar("Invalid Password")
                return;
            }
            updateUserPhone("+" + toBeUpdatedAccountData.phone, toBeUpdatedAccountData.phoneUpdatePassword).then((response) => {
                setUserAccount(response)
                showSnackBar("User Phone Number has been updated.")
            }).catch(error => {
                showSnackBar("Failed to Update User Phone Number")
            })
            setToBeUpdatedAccountData({})
        }

        setDialogOpen(initialDialogState)
    }

    return <div className='flex h-screen bg-background-color'>
        <SideBar ref={sidebarRef}></SideBar>

        {/* <This is the main content */}
        <div className="overflow-y-auto grow">
            <div className="flex-0">
                <div className="px-6 py-5 text-slate-800 flex justify-between">
                    <div className="flex items-center gap-3 transition-all	ease-out delay-100 duration-400 lg:gap-0">
                        <span className="material-symbols-rounded text-accent-color text-xl transition-all ease-out delay-100 duration-400 block lg:invisible cursor-pointer"
                            id="menu-btn" onClick={openSidebar}>
                            menu
                        </span>
                    </div>
                    <Header />
                </div>
            </div>
            <div className='p-6'>
                <div className='sm:justify-start mt-4 xl:w-3/4'>
                    <h3 className='text-title'>Your profile info in Fixale services</h3>
                    <h3 className='mt-3 font-light text-paragraph'>Personal info and options to manage it. You can make some of this info, like your contact details, visible to others so they can reach you easily. You can also see a summary of your profiles.</h3>
                </div>
                <div className='mt-8 sm:justify-start xl:w-3/4'>
                    <h3 className='text-title font-normal'>Basic info</h3>
                    <h3 className='mt-3 text-paragraph font-light'>Some info may be visible to other people using Fixale services. Learn more</h3>
                </div>

                <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5 px-8 py-6">
                        <a className="flex hover:bg-slate-50 cursor-pointer items-center" onClick={() => { setDialogOpen({ ...dialogOpen, profileOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Photo</h3>
                            <div className="grow">
                                <h3 className="hidden xl:block">A photo helps personalize your acount</h3>
                            </div>
                            <img src={userProfileImage} onError={() => setUserProfileImage(`https://faw.fixale.com/v1/avatars/initials?name=${userAccount.name}&project=63e56e42b7f8dd341816`)}
                                 alt="Profile pic" className='h-9 rounded-full' />
                        </a>

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, nameOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Name</h3>
                            <h3 className="grow">{userAccount.name}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, dobOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Birthday</h3>
                            <h3 className="grow">{new Date(userData.DateOfBirth).toLocaleDateString()}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, genderOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Gender</h3>
                            <h3 className="grow">{userData.Gender === "none" ? "Not Specified" : (userData.Gender[0].toUpperCase() + userData.Gender.substring(1))}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>
                    </div>
                </div>

                <div className='mt-8'>
                    <h3 className='text-title font-normal'>Contact info</h3>
                </div>

                <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5 px-8 py-6">

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, emailOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Email</h3>
                            <h3 className="grow">{userAccount.email}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, phoneOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Phone</h3>
                            <h3 className="grow">{userAccount.phone}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>
                    </div>
                </div>

                <div className='mt-8'>
                    <h3 className='text-title font-normal'>Address</h3>
                </div>

                <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5 px-8 py-6">

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, addressOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Address</h3>
                            <h3 className="grow">{userData.Address + " " + userData.Address2}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, billingAddressOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Billing Address</h3>
                            <h3 className="grow">{userData.BillingAddress + " " + userData.BillingAddress2}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>
                    </div>
                </div>

                <div className='mt-8'>
                    <h3 className='text-title font-normal'>Other info</h3>
                </div>

                <div className="rounded border border-color-grey-light bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5 px-8 py-6">

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, languageOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Language</h3>
                            <h3 className="grow">{!userData.prefs ? "EN" : userData.prefs.lang}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>

                        <a className="flex hover:bg-slate-50 cursor-pointer" onClick={() => { setDialogOpen({ ...dialogOpen, passwordOpen: true }) }}>
                            <h3 className="flex-none text-slate-600 font-semibold w-2/5">Password</h3>
                            <h3 className="grow">Last Changed: {new Date(userAccount.passwordUpdate).toString()}</h3>
                            <span className="material-symbols-rounded text-accent-color">
                                navigate_next
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <Dialog open={dialogOpen.profileOpen} onClose={() => cancelUserDataDialog('profileOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-title">Set your display profile picture</h3>
                    <h3 className="text-xs mt-1 text-paragraph">A profile picture helps you personalize
                        your account. Only .PNG .JPG .JPEG and .GIF are allowed</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="file" name="file" id="file" accept={"image/*"}
                        className="f-full overflow-hidden hidden absolute -z-10 w-0 h-0 px-4 py-10" />
                    <label htmlFor="file"
                        className="w-full text-slate-700 bg-slate-100 text-center inline-block cursor-pointer px-4 py-6 border-dashed border-color-grey-light border-2">
                        <div className="flex items-center justify-center gap-5">
                            <span className="material-symbols-rounded text-color-grey">
                                folder_open
                            </span>
                            <h3 className="text-paragraph text-color-grey">Choose a picture</h3>
                        </div>
                    </label>
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserDataDialog('profileOpen')} >CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserData}>DONE</button>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.nameOpen} onClose={() => cancelUserAccountDialog('nameOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your full name</h3>
                    <h3 className="text-xs mt-1 tracking-normal text-paragraph">Name of the user helps recognize your account,
                        its used to
                        process billing</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="text" name="fullname" id="fullname" value={toBeUpdatedAccountData.name ? toBeUpdatedAccountData.name : userAccount.name} onChange={(e) => { setToBeUpdatedAccountData({ name: e.target.value }) }}
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserAccountDialog('nameOpen')}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserAccount}>DONE</button>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.dobOpen} onClose={() => cancelUserDataDialog('dobOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your DOB</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">DOB of the user helps recognize your account,
                        its used to
                        process billing</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="date" name="userdob" id="userdob" value={new Date(toBeUpdatedUserData.DateOfBirth).toISOString().split('T')[0]}
                        className="rounded border border-color-grey-light text-color-grey outline-none w-full py-1.5 px-4" onChange={(e) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, DateOfBirth: new Date(e.target.value).toISOString() }) }} />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserDataDialog('dobOpen')}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserData}>DONE</button>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.genderOpen} onClose={() => cancelUserDataDialog('genderOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium">Select your gender</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">Gender of the user helps recognize your
                        account, its used
                        to
                        process billing</h3>
                </div>
                <div className="px-4 mb-4">
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="other"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value="male" control={<Radio
                                checked={(toBeUpdatedUserData.Gender === "male")}
                                value={"male"}
                                onChange={(e) => {if (e.target.checked) setToBeUpdatedUserData({...toBeUpdatedUserData, Gender: "male"})}}
                                name={"color-radio-button-demo"}
                                inputProps={{ 'aria-label': "female" }}
                                sx={{
                                    color: '#0AD7A5',
                                    '&.Mui-checked': {
                                        color: '#0AD7A5',
                                    },
                                }}
                            />} label="Male" />
                            <FormControlLabel value="female" control={<Radio
                                checked={(toBeUpdatedUserData.Gender === "female")}
                                value={"female"}
                                onChange={(e) => {if (e.target.checked) setToBeUpdatedUserData({...toBeUpdatedUserData, Gender: "female"})}}
                                name={"color-radio-button-demo"}
                                inputProps={{ 'aria-label': "female" }}
                                sx={{
                                    color: '#0AD7A5',
                                    '&.Mui-checked': {
                                        color: '#0AD7A5',
                                    },
                                }}
                            />} label="Female" />
                            <FormControlLabel value="other" control={<Radio
                                checked={(toBeUpdatedUserData.Gender !== "male" && toBeUpdatedUserData.Gender !== "female")}
                                value={"other"}
                                onChange={(e) => {if (e.target.checked) setToBeUpdatedUserData({...toBeUpdatedUserData, Gender: "other"})}}
                                name={"color-radio-button-demo"}
                                inputProps={{ 'aria-label': "female" }}
                                sx={{
                                    color: '#0AD7A5',
                                    '&.Mui-checked': {
                                        color: '#0AD7A5',
                                    },
                                }}
                            />} label="Prefer not to Say" />
                        </RadioGroup>
                    </FormControl>

                    <div className="flex flex-row justify-end px-4 gap-4">
                        <button
                            className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserDataDialog('genderOpen')}>CANCEL</button>
                        <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserData}>DONE</button>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.emailOpen} onClose={() => cancelUserAccountDialog('emailOpen')} className="round">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your contact email</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">Contact email of the user helps us recognize
                        your account, and send important notifications</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="email" name="email-address" id="email-address" value={toBeUpdatedAccountData.email ? toBeUpdatedAccountData.email : userAccount.email}
                        onChange={(e) => { setToBeUpdatedAccountData({ ...toBeUpdatedAccountData, email: e.target.value }) }}
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="px-4 mb-4">
                    <input type="password" name="email-change-password" id="email-change-password" value={toBeUpdatedAccountData.emailUpdatePassword ? toBeUpdatedAccountData.emailUpdatePassword : ""}
                           onChange={(e) => { setToBeUpdatedAccountData({ ...toBeUpdatedAccountData, emailUpdatePassword: e.target.value }) }}
                           placeholder='Enter password'
                           autoComplete="off"
                           className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserAccountDialog('emailOpen')}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserAccount}>DONE</button>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.phoneOpen} onClose={() => cancelUserAccountDialog('phoneOpen')} className="round">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your mobile number</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">Mobile number of the user helps you recognize
                        your account, its
                        used in billing processing</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="number" name="mobile-number" id="mobile-number" value={toBeUpdatedAccountData.phone ? toBeUpdatedAccountData.phone : userAccount.phone}
                        onChange={(e) => { setToBeUpdatedAccountData({ ...toBeUpdatedAccountData, phone: e.target.value }) }}
                        placeholder='Phone Number excluding +'
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="px-4 mb-4">
                    <input type="password" name="phone-change-password" id="phone-change-password" value={toBeUpdatedAccountData.phoneUpdatePassword ? toBeUpdatedAccountData.phoneUpdatePassword : ""}
                           onChange={(e) => { setToBeUpdatedAccountData({ ...toBeUpdatedAccountData, phoneUpdatePassword: e.target.value }) }}
                           placeholder='Enter your password'
                           className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserAccountDialog('phoneOpen')}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserAccount}>DONE</button>
                </div>
            </div>
        </Dialog >

        <Dialog open={dialogOpen.addressOpen} onClose={() => cancelUserDataDialog('addressOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your full address</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">Address of the user helps you recognize
                        youraccount, its
                        used in billing processing</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="text" name="address-line-1" id="address-line-1" placeholder='Addressline 1' value={toBeUpdatedUserData.Address}
                        onChange={(e) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, Address: e.target.value }) }}
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="px-4 mb-4">
                    <input type="text" name="address-line-2" id="address-line-2" placeholder='Addressline 2' value={toBeUpdatedUserData.Address2}
                        onChange={(e) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, Address2: e.target.value }) }}
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="px-4 mb-4">
                    <CountryDropdown
                        className="rounded border border-color-grey-light outline-none w-full py-2 px-4 bg-white"
                        value={toBeUpdatedUserData.Country}
                        onChange={(val) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, Country: val }) }} />
                </div>
                <div className="px-4 mb-4">
                    <RegionDropdown
                        className="rounded border border-color-grey-light outline-none w-full py-2 px-4 bg-white"
                        country={toBeUpdatedUserData.Country}
                        value={toBeUpdatedUserData.Region}
                        onChange={(val) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, Region: val }) }} />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserDataDialog('addressOpen')}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserData}>DONE</button>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.billingAddressOpen} onClose={() => cancelUserDataDialog('billingAddressOpen')} className="round">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Enter your billing address</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">Address of the user helps you recognize
                        youraccount, its
                        used in billing processing</h3>
                </div>
                <div className="px-4 mb-4">
                    <input type="text" name="billing-address-line-1" id="billing-address-line-1" placeholder='Addressline 1' value={toBeUpdatedUserData.BillingAddress}
                        onChange={(e) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, BillingAddress: e.target.value }) }}
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="px-4 mb-4">
                    <input type="text" name="billing-address-line-2" id="billing-address-line-2" placeholder='Addressline 2' value={toBeUpdatedUserData.BillingAddress2}
                        onChange={(e) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, BillingAddress2: e.target.value }) }}
                        className="rounded border border-color-grey-light outline-none w-full py-1.5 px-4" />
                </div>
                <div className="px-4 mb-4">
                    <CountryDropdown
                        className="rounded border border-color-grey-light outline-none w-full py-2 px-4 bg-white"
                        value={toBeUpdatedUserData.BillingCountry}
                        onChange={(val) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, BillingCountry: val }) }} />
                </div>
                <div className="px-4 mb-4">
                    <RegionDropdown
                        className="rounded border border-color-grey-light outline-none w-full py-2 px-4 bg-white"
                        country={toBeUpdatedUserData.BillingCountry}
                        value={toBeUpdatedUserData.BillingRegion}
                        onChange={(val) => { setToBeUpdatedUserData({ ...toBeUpdatedUserData, BillingRegion: val }) }} />
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserDataDialog('billingAddressOpen')}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserData}>DONE</button>
                </div>
            </div>
        </Dialog >

        <Dialog open={dialogOpen.languageOpen} onClose={() => setDialogOpen({ ...dialogOpen, languageOpen: false })} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Select the language</h3>
                    <h3 className="text-xs mt-1 tracking-normal text-paragraph">Language helps translating the webpage and sending user cusmizes notifications</h3>
                </div>
                <div className="px-4 mb-4">
                    <select name="cars" id="cars" className='rounded border bg-white border-color-grey-light outline-none w-full py-1.5 px-4'>
                        <option value="en">English</option>
                    </select>
                    {/* <LanguageDropdown className="rounded border bg-white border-color-grey-light outline-none w-full py-1.5 px-4"/> */}
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => { setDialogOpen({ ...dialogOpen, languageOpen: false }) }}>CANCEL</button>
                    <button className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" onClick={updateUserData}>DONE</button>
                </div>
            </div>
        </Dialog>

        <Dialog open={dialogOpen.passwordOpen} onClose={() => cancelUserAccountDialog('passwordOpen')} className="rounded">
            <div className="p-4">
                <div className="px-4 mb-4">
                    <h3 className="font-medium text-lg">Change password</h3>
                    <h3 className="text-paragraph mt-1 tracking-normal">To change password you will be redirected to auth.fixale.com</h3>
                </div>
                <div className="flex flex-row justify-end px-4 gap-4">
                    <button
                        className="px-2.5 py-1 text-color-grey text-paragraph" onClick={() => cancelUserAccountDialog('passwordOpen')}>CANCEL</button>
                    <a className="px-2.5 py-1 text-paragraph rounded bg-accent-color text-white" target="__blank" href='https://auth.fixale.com'>PROCEED</a>
                </div>
            </div>
        </Dialog>
        <Snackbar
            autoHideDuration={3000}
            onClose={() => setSnackbarOpened(false)}
            open={snackbarOpened}
            message={snackbarMessage}
        />
    </div >
}
export default PersonalInfo;