import React from 'react'
import { Link } from 'react-router-dom';

function Error() {
    return (
        <div className="overflow-x-hidden relative bg-background-color">
            <div className="flex flex-col justify-center items-center h-full sm:h-screen overflow-hidden">
                <div className="sm:rounded py-6 px-10 w-full sm:w-[55%] md:w-[40%] lg:w-[37%] xl:w-[32%] border-0 sm:bg-white flex flex-col justify-center">
                    <h3 className="text-md font-semibold mb-4 w-full text-center sm:mt-2">Something went wrong</h3>
                    <div className="mb-2">
                        <div className="flex flex-col">

                        </div>
                    </div>
                    <h3 className="text-paragraph decoration-indigo-600 w-full text-center">Uh Oh! Something went wrong. Please try again later. If you are still facing issues, please contact support. support@fixale.com</h3>
                    <button className="w-full mt-4">
                        <h3 className="rounded bg-accent-color text-white px-4 py-1.5">
                            <Link to="/">
                                Go Back
                            </Link>
                        </h3>
                    </button>

                </div>
                <h3 className="text-paragraph text-slate-700 mt-4">&copy; Copyright 2024
                    <script>new Date().getFullYear() {'>'} 2019 && document.write(" - " + new Date().getFullYear());</script>, Fixale Softwares PVT LTD.
                </h3>
            </div>
        </div>
    )
}

export default Error;