import React, {useRef} from 'react';
import { useEffect } from "react";
import SideBar from '../components/SideBar';

import {
    Link
} from "react-router-dom";
import Header from '../components/Header';

function PaymentsAndSubscriptions() {

    const sidebarRef = useRef()

    function openSidebar() {
        sidebarRef.current.setSidebarDisplay("block");
    }


    return <div className='flex h-screen bg-background-color'>
        <SideBar ref={sidebarRef}></SideBar>

        {/* <This is the main content */}
        <div className="overflow-y-auto grow">
            <div className="flex-0">
                <div className="px-6 py-5 text-slate-800 flex justify-between">
                    <div className="flex items-center gap-3 transition-all	ease-out delay-100 duration-400 lg:gap-0">
                        <span className="material-symbols-rounded text-accent-color text-xl transition-all ease-out delay-100 duration-400 block lg:invisible cursor-pointer"
                              id="menu-btn" onClick={openSidebar}>
                            menu
                        </span>
                    </div>
                    <Header/>
                </div>
            </div>
            <div className='p-6'>
                <div className='sm:justify-start mt-4 xl:w-3/4'>
                    <h3 className='text-title'>Payments and subscriptions</h3>
                    <h3 className='mt-3 font-light text-paragraph'>Manage payments and subscription for your fixale account</h3>
                </div>

                <div className='mt-8 sm:justify-start xl:w-3/4'>
                    <h3 className='text-title font-normal'>Payment methods</h3>
                </div>

                <div className="rounded border border-color-grey-light overflow-hidden bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5  pt-6">
                        <h3 className='text-paragraph mx-8'>These can be used to make sure it's really you signing in or to reach you if there's suspicious activity in your account</h3>
                        <div className='bg-accent-color-light text-accent-color px-8 py-2'>
                            <a href='https://payments.fixale.com' target="_blank">Manage payment method</a>
                        </div>
                    </div>
                </div>

                <div className='mt-8 sm:justify-start xl:w-3/4'>
                    <h3 className='text-title font-normal'>Purchases</h3>
                </div>

                <div className="rounded border border-color-grey-light overflow-hidden bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5  pt-6">
                        <h3 className='text-paragraph mx-8'>Your purchases, including deliveries and other online orders, made using Fixale for its products can be managed from here</h3>
                        <div className='bg-accent-color-light text-accent-color px-8 py-2'>
                            <a href='https://payments.fixale.com' target="_blank">Manage purchases</a>
                        </div>
                    </div>
                </div>

                <div className='mt-8 sm:justify-start xl:w-3/4'>
                    <h3 className='text-title font-normal'>Subscriptions</h3>
                </div>

                <div className="rounded border border-color-grey-light overflow-hidden bg-white flex flex-col text-paragraph gap-2 justify-center sm:justify-start mt-4 xl:w-3/4">
                    <div className="flex flex-col gap-5  pt-6">
                        <h3 className='text-paragraph mx-8'>Yourrecurring payments for subscription services, like monetization and build limit can be managed from here</h3>
                        <div className='bg-accent-color-light text-accent-color px-8 py-2'>
                            <a href='https://payments.fixale.com' target="_blank">Manage subscriptions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default PaymentsAndSubscriptions;