import './App.css';
import React from "react";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Home from './pages/Home';
import PersonalInfo from './pages/PersonalInfo';
import Security from './pages/Security';
import DataAndPrivacy from './pages/DataAndPrivacy';
import PaymentsAndSubscriptions from './pages/PaymentsAndSubscriptions';
import Error from './pages/Error';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ConfirmEmail from './pages/ConfirmEmail';
import ResetPassword from './pages/ResetPassword';
import Authentication from './pages/Authentication';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Error404 from './pages/Error404';
import NonUserRoutes from "./components/NonUserRoutes";
import CacheBuster from "react-cache-buster";

function App() {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
        <CacheBuster
            currentVersion={process.env.REACT_APP_CURRENT_VERSION}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
        >
            <Router>
                <Routes>
                    <Route element={<ProtectedRoutes/>}>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/personalinfo" element={<PersonalInfo/>}/>
                        <Route path="/dataprivacy" element={<DataAndPrivacy/>}/>
                        <Route path="/security" element={<Security/>}/>
                        <Route path="/payments" element={<PaymentsAndSubscriptions/>}/>
                    </Route>
                    <Route element={<NonUserRoutes/>}>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/signup" element={<SignUp/>}/>
                        <Route path="/reset" element={<ResetPassword/>}/>
                    </Route>
                    <Route path="/error" element={<Error/>}/>
                    <Route path="/error404" element={<Error404/>}/>
                    <Route path="/confirm" element={<ConfirmEmail/>}/>
                    <Route path="/auth" element={<Authentication/>}/>
                </Routes>
            </Router>
        </CacheBuster>
    );
}

export default App;
