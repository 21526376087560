import {getCurrentSession, getUserAccount} from "../utils/AppWrite";
import {useEffect, useState} from "react";
import {Navigate, Outlet, useNavigate} from "react-router-dom";

function ProtectedRoutes() {

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getUserAccount().then(userAccountData => {
            if (userAccountData.emailVerification === false) {
                navigate("/confirm");
            }else {
                setIsLoading(false);
            }
        }).catch(e => {
            navigate("/login");
            setIsLoading(false);
        })
    }, [])

    if (isLoading) {
        return (<div></div>)
    }

    return <Outlet />
}

export default ProtectedRoutes;